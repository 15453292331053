.image-carousel {
  padding: 8rem 0;
  background-color: #F2F2F9;

  @include mobile {
    padding: 4rem 0;
  }

  &__wrap {
    display: flex;
    align-items: center;

    @include tablet {
      flex-direction: column;
    }
  }

  &__left {
    width: 38.4rem;

    @include tablet {
      width: 100%;
    }

    h2 {
      margin: 0.8rem 0 0;

      @include mobile {
        margin: 2.4rem 0 0;
      }
    }

    &__buttons {
      margin: 4.8rem 0 0;
      display: flex;
      align-items: center;

      @include mobile {
        margin: 4rem 0 0;
        flex-direction: column;
        align-items: flex-start;
      }

      .btn-group {
        margin: 0 3.2rem 0 0;

        @include mobile {
          margin: 0 0 2.4rem;
        }
        
        button {
         border: none;
        }
      }

    }

    &__list {
      margin: 6.4rem 0 0;

      @include mobile {
        margin: 4rem 0 0;
      }
      &__item {
        display: flex;
        align-items: center;
        margin: 0 0 3.4rem;
        border: none;
        padding: 0;
        background-color: transparent;

        &.is-active {
          p {
            color: var(--blue);
            font-weight: 500;
          }

          svg {
            filter: grayscale(0%);
          }
        }

        &:nth-last-child(1) {
          margin: 0;
        }

        svg {
          margin: 0 2.4rem 0 0;
          filter: grayscale(100%);
          @include transition(filter);

          @include mobile {
            margin: 0 1.6rem 0 0;
          }
        }

        p {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 2.4rem;
        }
      }
    }
  }

  &__right {
    flex: 1;
    margin: 0 0 0 12.7rem;

    @include tablet {
      flex: none;
      width: 100%;
      margin: 4rem 0 0;
    }

    &__wrap {
      position: relative;
      aspect-ratio: 589/485;
    }

    &__item {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 0.8rem;
      overflow: hidden;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
