.accordion {
  border-bottom: 0.1rem solid var(--black);
  overflow: hidden;

  &.is-active {
    .accordion__trigger__plus {
      &::before {
        transform: translate(-50%, -50%) scaleY(0);
      }
    }
  }


  &__trigger {
    padding: 2.8rem 0;
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    text-align: left;
    align-items: center;
    color: #070A21;

    @include mobile {
      padding: 2.4rem 0;
    }

    h3 {
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 3.2rem;

      @include extra-large{
        font-size: 3.2rem;
        line-height: 4.8rem;
      }

      @include mobile {
        font-size: 2rem;
      }
    }
    &__plus {
      flex: none;
      margin: 0 0 0 2.4rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background-color: #F2F2F9;
      position: relative;

      @include extra-large{
        width: 6rem;
        height: 6rem;
      }

      &::after {
        content: "";
        position: absolute;
        border-radius: 0.2rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1.6rem;
        height: 0.2rem;
        background-color: var(--black);
      }

      &::before {
        content: "";
        position: absolute;
        border-radius: 0.2rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 1.6rem;
        width: 0.2rem;
        background-color: var(--black);
        transform-origin: center center;
        @include transition(all);
      }
    }
  }

  &__content {
    height: 0;

    &__wrap {
      padding: 0 0 2.8rem;
      

      @include extra-large{
        padding: 0 0 3.2rem;
      }

      @include mobile {
        padding: 0 0 2.4rem;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 2.4rem;
        @include extra-large{
          font-size: 28px;
          line-height: 4.2rem;
        }
      }
    }
  }
}
