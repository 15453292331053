.pre-footer {
  background-color: #f5f6fe;
  padding: 120px 0px;
  position: relative;
  border-radius: 32px 32px 0 0;

  @include extra-large {
    padding: 240px 0;
  }

  @include mobile {
    padding: 80px 0;
  }

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #070a21;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 16px;
  }

  &__heading {
    text-align: center;
    color: #070a21;
    font-size: 48px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 64px;
    margin: 24px 0;

    @include extra-large {
      font-size: 64px;
      margin: 36px 0;
    }

    @include mobile {
      font-size: 32px;
      margin: 20px 0 10px 0;
    }
  }

  &__description {
    text-align: center;
    color: #4b4d66;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 32px;

    @include mobile {
      font-size: 16px;
    }
  }

  &__logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 996px;

    @include mobile {
      overflow: scroll;
    }
  }

  &__slider {
    width: 100%;
    padding: 16px;

    @include mobile {
      padding: 0px;
    }
  }

  & .slides__item {
    padding: 0 24px 0 0;
    border-right: 1px solid #070a21;
    border-radius: 0;
    width: 386px !important;
    background: transparent;

    @include extra-large{
      width: 560px !important;
    }

    @include mobile {
      width: 360px !important;
    }

    &:last-child {
      border: none;
    }
    & a {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  & .slides__slider::after,
  & .slides__slider::before {
    background: linear-gradient(
      270deg,
      #f5f6fe 0%,
      rgba(245, 246, 254, 0) 191.3%
    );
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;

    @include mobile {
      gap: 40px;
    }

    & > a {
      width: fit-content;
      padding: 12px 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      border-radius: 24px;

      
    @include extra-large {
      font-size: 24px;
      padding: 24px 36px;
      border-radius: 36px;
    }

      @include mobile {
        width: 100%;
      }
    }
  }

  &__title-text {
    font-family: Poppins;
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    color: #070a21;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    @include extra-large{
      font-size: 38px;
      line-height: 50px;
    }
  }

  &__blog-description {
    font-family: Poppins;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    color: #2b2f40;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    margin-top: 16px;

    @include extra-large{
      font-size: 24px !important;
      line-height: 36px !important;
    }
  }

  &__tag {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #070a21;
    background: #f5f6fe;
    border-radius: 29px;
    padding: 8px;

    @include extra-large{
      font-size: 18px;
    }

    &-list {
      position: absolute;
      top: 16px;
      left: 16px;
      width: calc(100% - 41px);
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  &__image {
    width: 100% !important;
    height: 220px !important;
    border-radius: 8px;
    margin-bottom: 24px !important;
    background: #dfecff;
    aspect-ratio: 1.636;

    @include extra-large{
      height: 400px !important ;
    }

  }
}

