.hero {
  padding: 13.2rem 0 5.6rem;
  background-color: #f2f2f9;
  position: relative;
  z-index: 1;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;

  @include tablet {
    padding: 14rem 0 4rem;
    height: 100%;
  }

  @include mobile {
    padding: 9.6rem 0 4rem;
    height: 100%;
  }

  &__wrap {
    display: flex;
    align-items: center;

    @include tablet {
      flex-wrap: wrap;
    }
  }

  &__left {
    flex: 1;

    @include tablet {
      width: 100%;
      flex: none;
      order: 3;
      margin: 2.4rem 0 0;
    }

    // img {
    //   width: 100%;
    //   height: auto;
    // }
    &__video {
      position: relative;
      padding-top: 6rem;
      @include tablet {
        padding-top: 2.4rem;
      }
      // &:after {
      //   content: "";
      //   display: block;
      //   padding-top: 104%;
      // }

      &__wrap {
        aspect-ratio: 594/528;
      }

      video {
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        mix-blend-mode: darken;
        transform: translate3d(0, 0, 0);
        z-index: -1;
      }
    }
  }

  &__right {
    width: 60.6rem;
    padding: 0 0 0 10rem;

    @include extra-large {
      width: 80rem;
    }

    &--wide {
      width: 65.6rem;
    }

    @include tablet {
      width: 100%;
      padding: 0;
      text-align: center;
    }

    h1 {
      margin: 2.4rem 0 0;
      @include extra-large {
        font-size: 8rem;
        font-weight: 900;
        line-height: 10rem;
      }
    }

    p {
      margin: 3.2rem 0 0;

      @include tablet {
        max-width: 35rem;
        margin: 3.2rem auto 0;
      }

      @include extra-large{
        font-size: 2.8rem;
        line-height: 3.6rem;
      }

    }

    &__button {
      margin: 4rem 0 0;

      a {
        margin: 0 3.2rem 0 0;

        @include mobile {
          margin: 0 0 2.4rem;
        }

        &:nth-last-child(1) {
          margin: 0;
        }
      }

      .btn {
        @include mobile {
          width: 100%;
        }
      }
    }
  }

  &__blocks {
    display: flex;
    margin: 0 -1.2rem -2.4rem;

    @include tablet {
      flex-wrap: wrap;
    }
  }

  &__block {
    width: 25%;
    padding: 0 1.2rem;
    margin: 0 0 2.4rem;

    @include tablet {
      width: 50%;
    }
    &__wrap {
      background-color: #fff;
      border-radius: 0.8rem;
      padding: 2.4rem;
      height: 100%;

      @include mobile {
        padding: 1.6rem 2rem;
        text-align: center;
      }

      h3 {
        font-size: 4rem;
        font-weight: 500;
        line-height: 4.8rem;

        @include mobile {
          font-size: 2.4rem;
          line-height: 3.2rem;
        }
      }
      p {
        margin: 0.8rem 0 0;
      }
    }
  }
}

.hero-blocks {
  background-color: #f2f2f9;
  padding: 0 0 5.6rem;

  @include mobile {
    padding: 0 0 2.4rem;
  }
}
