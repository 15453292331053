.code {
  margin: 12rem 0;

  @include mobile {
    margin: 8rem 0 4rem;
  }
  &__header {
    text-align: center;

    h2 {
      margin: 2.4rem 0 0;
    }

    p {
      margin: 2.4rem auto 0;
      max-width: 49.4rem;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    margin: 8rem -6.3rem;

    @include tablet {
      margin: 8rem 0 0;
      flex-direction: column;
    }

    @include mobile {
      margin: 4rem 0 0;
    }

    &__left {
      width: 54.25%;
      padding: 0 6.3rem;

      @include tablet {
        width: 100%;
        padding: 0;
      }
    }

    &__right {
      width: 45.75%;
      padding: 0 6.3rem;

      @include tablet {
        width: 100%;
        padding: 0;
        margin: 4rem 0 0;
      }

      h3 {
        font-size: 4rem;
        font-weight: 500;
        line-height: 4.8rem;
      }

      p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
        margin: 2.4rem 0 0;
      }

      &__buttons {
        margin: 5.6rem 0 0;

        @include mobile {
          margin: 4rem 0 0;
        }

        a {
          margin: 0 2.4rem 0 0;

          &:nth-last-child(1) {
            margin: 0;
          }
        }
      }
    }
  }

  &__tabs {
    background-color: #EAEAF7;
    border-radius: 0.8rem;
    position: relative;
    overflow: hidden;

    &.is-active {
      &::after {
        opacity: 0;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% - 1.6rem);
      height: 100%;
      background: linear-gradient(180deg, rgba(234, 234, 247, 0.00) 69%, #EAEAF7 100%);
      pointer-events: none;
      @include transition(opacity);
    }

    &__content {
      height: 100%;
    }

    &__wrap {
      padding: 4rem 5.8rem;
      max-height: 62.4rem;
      position: relative;
      overflow: auto;

      @include mobile {
        padding: 2.4rem;
        max-height: 55rem;
      }


    }

    //@include tablet {
    //  min-height: 50rem;
    //}
    //
    //@include mobile {
    //  padding: 2.4rem 1.6rem;
    //  min-height: auto;
    //}

    //&__item {
    //  border-bottom: 0.1rem solid #A7A8B7;
    //  padding: 1.6rem 0;
    //  overflow: hidden;
    //
    //  &.is-active {
    //    .code__tabs__item__trigger {
    //      background-color: var(--white);
    //      span {
    //        background-color: #E0E1F9;
    //        &::before {
    //          opacity: 1;
    //        }
    //      }
    //    }
    //  }
    //
    //  &:nth-last-child(1) {
    //    margin: 0;
    //  }
    //  &__trigger {
    //    width: 100%;
    //    text-align: left;
    //    padding: 1.6rem 0 1.6rem 5.6rem;
    //    background-color: transparent;
    //    border: none;
    //    position: relative;
    //    border-top-left-radius: 0.8rem;
    //    border-top-right-radius: 0.8rem;
    //    font-size: 1.4rem;
    //    font-weight: 400;
    //    line-height: 2.4rem;
    //    color: #2B2F40;
    //
    //    @include mobile {
    //      padding: 1.6rem 0 1.6rem 3.6rem;
    //      font-size: 1.2rem;
    //      line-height: 1.2rem;
    //    }
    //
    //    span {
    //      width: 2.4rem;
    //      height: 2.4rem;
    //      background-color: var(--white);
    //      display: flex;
    //      align-items: center;
    //      justify-content: center;
    //      position: absolute;
    //      left: 1.6rem;
    //      border-radius: 50%;
    //      top: 1.6rem;
    //
    //      @include mobile {
    //        width: 1.8rem;
    //        height: 1.8rem;
    //        left: 1.2rem;
    //        top: 1.2rem;
    //      }
    //
    //      &::before {
    //        content: "";
    //        display: block;
    //        width: 0.8rem;
    //        height: 0.8rem;
    //        border-radius: 50%;
    //        background-color: var(--blue);
    //        opacity: 0;
    //      }
    //    }
    //  }
    //
    //  &__content {
    //    background-color: var(--white);
    //    border-bottom-left-radius: 0.8rem;
    //    border-bottom-right-radius: 0.8rem;
    //    height: 0;
    //    overflow: hidden;
    //
    //    p {
    //      font-size: 1.4rem;
    //      font-weight: 400;
    //      line-height: 2.4rem;
    //      color: #2B2F40;
    //      overflow-wrap: break-word;
    //
    //      @include mobile {
    //        font-size: 1.2rem;
    //        line-height: 1.6rem;
    //      }
    //    }
    //
    //    &__wrap {
    //      padding: 0 1.6rem 1.6rem 5.6rem;
    //
    //      @include mobile {
    //        padding: 0 1.6rem 1.6rem 3.6rem;
    //      }
    //    }
    //  }
    //}
  }


}
