.section-header {
  text-align: center;

  h2 {
    margin: 2.4rem auto 0;

    @include extra-large {
      font-size: 8rem;
      margin: 3.6rem auto 0;
      line-height: 10rem;
    }
  }

  p {
    margin: 3rem auto 0;

    @include extra-large {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    @include mobile {
      margin: 2.4rem auto 0;
    }
  }

  p.zero-margin {
    margin: 0;
  }
}
