.leading-communities {
  padding: 120px 0;
  border-radius: 32px 32px 0 0;

  @include mobile {
    padding: 80px 0;
  }

  @include extra-large {
    padding: 240px 0;
  }

  &__heading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      flex-direction: column;
      gap: 24px;
    }

    &__heading {
      display: flex;
      flex-direction: column;
      gap: 24px;
      color: #070a21;
      font-weight: 500;
      font-size: 48px;
      font-family: Poppins;
      line-height: 64px;
      width: 100%;

      @include mobile {
        font-size: 32px;
        line-height: 40px;
        width: 100%;
      }

      @include extra-large {
        font-size: 68px;
        padding-bottom: 48px;
        line-height: 80px;
      }

      &__navigation {
        display: flex;
        align-items: center;
        color: #070a21;
        gap: 8px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;

        @include extra-large {
          font-size: 20px;
        }
      }
    }
    &__description {
      color: #4b4d66;
      font-weight: 400;
      font-size: 20px;
      font-family: Poppins;
      line-height: 32px;
      width: 50%;

      @include mobile {
        font-size: 14px;
        line-height: 24px;
        width: 100%;
      }
      @include extra-large {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  &__tabs {
    margin: 80px 0 160px 0;

    @include extra-large {
      font-size: 3rem !important;
    }

    @include mobile {
      margin: 40px 0 80px 0;
    }

    & .tabs__content {
      margin: 48px 0 0;
      padding: 0;
    }
    & .tabs__tabs {
      margin: 0;
    }

    & .tabs__tabs__wrap {
      @include mobile {
        margin: 0;
      }
    }

    &__content-container {
      justify-content: space-between;
      overflow: auto;

      @include mobile {
        flex-direction: row;
      }
    }
    & .featured-app-list {
      display: flex;
      gap: 36px;

      @include mobile {
        gap: 24px;
      }
    }
    & .featured-app {
      background: #f2f2f9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      border-radius: 12px;
      width: 170px;
      height: 170px;
      padding: 15px;

      @include extra-large {
        width: 240px;
        height: 240px;
      }

      @include mobile {
        width: 143px;
        height: 143px;
      }

      &.no-logo {
        text-decoration: none;
        font-size: 30px;
        font-weight: 500;
        color: #000;

        & a {
          text-decoration: none;
          font-size: 30px;
          font-weight: 500;
          color: #000;
        }
      }
    }
    & .view-more-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      color: #424cea;
      font-size: 16px;
      font-family: poppins;
      line-height: 24px;
      font-weight: 500;
      white-space: nowrap;
      width: 170px;
      height: 170px;

      @include extra-large {
        width: 240px;
        height: 240px;
      }

      & a span {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #424cea;

        @include extra-large {
          font-size: 24px;
        }
      }
    }
  }

  &__info-section {
    display: flex;
    width: 100%;
    gap: 100px;
    margin-bottom: 160px;

    @include mobile;
    @include tablet {
      margin-bottom: 80px;
      flex-direction: column;
      gap: 80px;
    }

    @media (min-width: 768px) and (max-width: 862px) {
      gap: 40px;
    }

    &__card {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        color: #070a21;
        font-family: poppins;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        padding: 29px 0 16px;
        text-align: center;

        @include extra-large {
          font-size: 36px;
          line-height: 40px;
          padding: 60px 0 32px;
        }

        @include mobile;
        @include tablet {
          padding: 24px 0 16px;
        }
      }
      .description {
        max-width: 490px;
        text-align: center;
        color: #2b2f40;
        font-family: poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        @include extra-large {
          font-size: 24px;
          line-height: 32px;
        }

        @include mobile;
        @include tablet {
          min-width: 0;
        }
      }
      .navigation-link {
        color: #424cea;
        padding-top: 32px;
        font-family: poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        display: flex;
        gap: 8px;
        align-items: center;
        white-space: nowrap;

        @include extra-large {
          padding-top: 46px;
          font-size: 26px;
        }

        @include mobile;
        @include tablet {
          padding-top: 24px;
        }
      }
    }
    &__vertical-line {
      border-right: 1px solid #2b2f40;
      @include mobile;
      @include tablet {
        display: none;
      }
    }
  }

  &__investors {
    @include mobile {
      margin-bottom: 80px;
    }
  }

  &__text {
    text-align: center;
    font-family: "Poppins" sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #070a21;

    @include extra-large {
      font-size: 24px;
    }
  }
  
  &__logos_slider {
    padding: 0px 120px;

    @include mobile {
      padding: 0px 24px;
    }

    .slides__item {
      padding: 0;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include extra-large {
        height: 120px !important;
      }

      @include mobile {
        height: 64px !important;
      }

      .small-img {
        width: 135px;
      }

      & img {
        height: auto;
        width: 180px;
        margin: 0;
        display: flex;
        align-items: center;
      }
    }

    & .slides__slider {
      margin: 40px 0 0;
    }

    .slides__slider::before,
    .slides__slider::after {
      background: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(245, 246, 254, 0) 191.3%
      );
      width: 120px;

      @include mobile {
        width: 24px;
      }
    }
  }
}

#leading_communities{
  width: 100%;
  height: 100%;
  background-color: white;
}
