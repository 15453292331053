.partners {
  margin: 11.25rem 0;

  @include mobile {
    margin: 5rem 0 3rem;
  }
  .section-header {
    padding-top: 6rem;

    h3 {
      margin: 0 auto;
      font-size: 1.65rem;
      font-weight: 500;

      @include mobile {
        font-size: 1.8rem;
      }
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 4rem;
    padding-bottom: 4.5rem;

    @include tablet {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      max-width: 550px;
      margin: 4.5rem auto;
      gap: 60px;
    }
    @include mobile {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      max-width: 369px;
      margin: 2rem auto;
      gap: 20px;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: 0 0 4rem;
    width: 100%;

    @include tablet {
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-items: center;
    height: 70px;
    img, svg {
      width: 200px;
      height: 100px;
      object-fit: contain;

      @include tablet {
      height: 39px;
      margin: 2rem 0;
    }
    @include mobile {
      height: 29px;
      width: 100px;
      margin: 2rem 0;
    }
    }
  }
}
