.tabs-image {
  margin: 12rem 0;

  @include tablet {
    margin: 8rem 0;
  }

  &__header {
    text-align: center;

    h2 {
      margin: 2.4rem 0 0;
    }

    p {
      margin: 2.4rem 0 0;
    }
  }

  &__content {
    margin: 8rem -6.3rem 0;
    display: flex;
    align-items: center;

    @include tablet {
      margin: 8rem 0 0;
      flex-direction: column;
    }

    @include mobile {
      margin: 4rem 0 0;
    }

    &__left {
      width: 46.25%;
      padding: 0 6.3rem;

      @include tablet {
        width: 100%;
        padding: 0;
      }
    }

    &__right {
      width: 53.75%;
      padding: 0 6.3rem;

      @include tablet {
        width: 100%;
        padding: 0;
        margin: 4rem 0 0;
      }
    }
    &__wrap {
      position: relative;
      aspect-ratio: 589/485;
    }

    &__item {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 0.8rem;
      overflow: hidden;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

  }

  &__tabs {
    border-left: 0.1rem solid #070A21;
    position: relative;

    &__indicator {
      position: absolute;
      width: 0.4rem;
      background-color: var(--blue);
      left: 0;
      top: 0;
      z-index: 1;
      border-radius: 0.2rem;
      @include transition(transform, height);
    }

    &__item {
      padding: 0 0 0 3.2rem;
      text-align: left;
      background-color: transparent;
      border: none;
      margin: 0 0 5.6rem;

      @include mobile {
        margin: 0 0 3.2rem;
        padding: 0 0 0 2.4rem;
      }

      &:nth-last-child(1) {
        margin: 0;
      }

      &.is-active {
        h3 {
          color: var(--blue);
        }
      }

      h3 {
        font-size: 2rem;
        font-weight: 500;
        line-height: 3.2rem;
        color: #070A21;
        @include transition(color);

        @include mobile {
          font-size: 1.8rem;
          line-height: 32px;
        }
      }

      p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
        margin: 1.6rem 0 0;

        @include mobile {
          font-size: 1.4rem;
        }
      }
    }
  }
}
