.icon-blocks {
  margin: 8rem 0;

  @include mobile {
    margin: 4rem 0;
  }

  &__blocks {
    display: flex;
    flex-wrap: wrap;
  }

  &__block {
    width: 33.3333%;
    padding: 1.6rem 4rem;
    height: auto;
    border-right: 0.1rem solid var(--black);

    h4{
      @include extra-large{
        font-size: 3.2rem;
        margin: 4rem 0rem;
      }
    }

    @include tablet {
      width: 100%;
      padding: 2rem 0;
      border-right: 0;
      // border-bottom: 0.1rem solid var(--black);
    }

    &:nth-child(1) {
      padding-left: 0;

      @include tablet {
        padding-top: 0;
      }
    }

    &:nth-last-child(1) {
      border-right: none;

      @include tablet {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    img {
      height: 4rem;
      width: auto;
      margin: 0 0 2.4rem;

      @include extra-large{
        height: 6rem;
        margin: 0 0  3.2rem;
      }

      @include mobile {
        height: 2.4rem;
      }
    }

    p {
      margin: 1.6rem 0 0;
      @include extra-large {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }
  }
}
