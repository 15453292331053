.main {
  &--footer {
    background-color: #000000;
    color: white;

    &--box {
      display: flex;
      justify-content: space-between;

      &--left {
        > p {
          color: white;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          max-width: 300px;
          margin-top: 16px;
        }
      }
      &--icons {
        display: flex;
        gap: 24px;
        margin-top: 34px;
        margin-bottom: 16px;
        > img {
          cursor: pointer;
        }
      }

      &--right {
        display: flex;
        gap: 100px;
        &--row {
          color: white;
          > h6 {
            margin-bottom: 26px;
          }
          > p {
            margin-bottom: 16px;
          }
          > h6 > a {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            color: white;
          }
          > p > a {
            color: white;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
          }
        }
      }
    }
    &--letterbox {
      position: relative;
      // margin-top: 89px;
      width: 100%;
      background: linear-gradient(
        0deg,
        #424cea 7%,
        rgba(66, 76, 234, 0) 68.93%
      );
      max-height: calc(28.2vw* 1.1);
      overflow: hidden;
      &--wrap {
        position: absolute;
        bottom: 0;
        display: flex;
        height: fit-content;
        align-items: flex-end;
      }

      &--letter {
        height: 100%;
        display: inline-block;
        background: linear-gradient(to top, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 1));
        -webkit-background-clip: text; 
        -webkit-text-fill-color: transparent;
        opacity: 0.2;
        font-size: 28.2vw;
        font-weight: 900;
        font-family: sans-serif;
        line-height: normal;
      }
    }
  }
}
