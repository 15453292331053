.nav--bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  &:hover {
    cursor: pointer;
  }

  @include tablet {
    position: fixed;
  }

  &.is-mobile-menu-active {
    box-shadow: none !important;
  }

  &.is-scrolled {
    .nav-wrapper {
      background-color: transparent;
      box-shadow: none;
      pointer-events: none;
      transform: translateY(-100%);
      display: none;
    }
    .nav-main {
      transform: translateY(-100%);
      pointer-events: none;
    }
    .nav-submenu {
      display: none !important;
    }
  }
}

.nav-wrapper {
  @include transition(transform, background-color, box-shadow);
  background-color: transparent;
  position: relative;
  padding: 0 4rem;

  @include mobile {
    padding: 0 24px;
  }

  &.is-fixed,
  &.is-hovering {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px rgba(43, 47, 64, 0.08);

    .nav-main {
      .nav-submenu-block {
        box-shadow: rgba(0, 0, 0, 0.05) 0px 73px 89px;
      }
    }
  }
}

.nav-main {
  @include transition(transform, background-color, box-shadow);
  @include tablet {
    border-radius: 0px;
    margin: 0;
  }
  margin: 0 auto;
  max-width: 1280px;
  background: transparent;
  display: flex;
  height: 85px;
  align-items: center;
  justify-content: space-between;

  &.is-fixed {
    position: fixed;
    margin: 25px 87.5px;
    width: -webkit-fill-available;
    top: 0;
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px rgba(43, 47, 64, 0.08);
  }
}
.nav__logo {
  width: 140px;
  height: 27px;
  flex-shrink: 1;
  z-index: 107 !important;
  
}
.nav__item {
  color: var(--www_videsigns_uk__1440x810_default-Woodsmoke, #0c0c0e);
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-decoration: none;
  
  > a {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -0.4rem;
      height: 0.1rem;
      width: 100%;
      background-color: var(--blue);
      transform: scaleX(0);
      transform-origin: left;
      @include transition(transform);
    }
    @include extra-large {
      font-size: 28px !important;
    }
  }

  @include tablet {
    display: none;
  }
}
.nav_lang_btn {
  display: flex;
  padding: 6px 13.02px 6px 13.79px;
  justify-content: center;
  align-items: center;
  border-radius: 1440px;
  border: 1px solid #000;
  background: transparent;
}
.nav_buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  @include tablet {
    display: none;
  }
}

.burger__custom {
  z-index: 107 !important;
}

.nav-submenu {
  @include transition(opacity, transform);
  opacity: 0;
  position: absolute;
  left: 0;
  top: 90%;
  width: 100%;
  height: 300px;
  pointer-events: none;
  transform: translateY(-30px);

  &:hover {
    display: block;
    opacity: 100;
    transform: translateY(0);
  }

  &-block {
    display: flex;
    background-color: white;
    justify-content: center;
    border-radius: 0 0 32px 32px;
    height: 100%;

    &::after {
      content: "";
      display: block;
      height: 0;
      width: 0;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: -2rem;
      height: 2rem;
      width: 100%;
      background-color: transparent;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 48px 0;
    height: 100%;
  }

  &-box {
    border-radius: 12px;
    border: 1px solid #dedede;
    background: #fafaff;
    box-shadow: 0px 2px 2px 0.5px rgba(49, 67, 81, 0.04),
      0px 1px 1px 0px rgba(49, 67, 81, 0.02);
    width: 346px;
    height: 235px;
    transition: all 0.2s ease-out;
    scale: 1;

    &:hover {
      scale: 1.02;
      box-shadow: 0px 0px 10px 2px rgba(31, 46, 57, 0.04);
    }

    &-info {
      height: 100%;
      padding: 22px;
      position: relative;
      > p {
        color: #5f5f5f;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-top: 17px;
        line-height: normal;
      }
      > img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }
  }
  &-list {
    width: max-content;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    &-divider {
      width: 284px;
      height: 1px;
      background-color: #dedede;
    }

    &-item {
      width: 284px;
      height: 70px;
    }

    h6 {
      color: var(--Black, #000);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
    }

    p {
      color: var(--Black, #000);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    a {
      display: flex;
      align-items: flex-start;
      height: 100%;
      width: 100%;
      cursor: pointer;

      &:hover {
        h6 {
          color: var(--blue);
        }
      }
    }
    &-logo-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      align-items: center;
      height: 100%;
      div {
        width: 218px;
        height: 100%;
      }
    }
  }
}

.nav__item {
  cursor: pointer;
  > svg {
    path {
      @include transition(fill);
    }
    @include transition(transform);
    transform: rotate(0deg);
  }
  &:hover {
    // border-bottom: 1px solid var(--blue);
    > a {
      color: var(--blue);
      &::after {
        transform: scaleX(1);
      }
    }

    .nav-submenu {
      display: block;
      opacity: 100;
      transform: translateY(-10px);
      pointer-events: all;
      &:hover {
        display: block;
        opacity: 100;
        transform: translateY(-10px);
      }
    }
    > svg {
      transform: rotate(-90deg);
      path {
        fill: var(--blue);
      }
    }
  }
}

.menu-mobile {
  @media screen and (min-width: 1025px) {
    display: none !important;
  }

  &__accordion {
    width: 100%;
    border-bottom: 1px solid #eeeeee;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 8px 0 16px;
      border-bottom: 1px solid #eeeeee;
      .title {
        color: #070a21;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        font-family: "poppins";
      }
    }
    &__trigger {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: #070a21;
      padding: 16px 0;
    }
  }
}

.accordion-home {
  overflow: hidden;

  &__trigger {
    padding: 2.8rem 0;
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    text-align: left;
    align-items: center;
    color: #070a21;

    @include mobile {
      padding: 2.4rem 0;
    }
  }

  &__content {
    height: 0;
  }
}
