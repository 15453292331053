.image-section {
  margin: 8rem 0;

  @include mobile {
    margin: 4rem 0 4rem;
  }

  &__image {
    margin: 8rem 0 0;

    @include mobile {
      margin: 4rem auto 0;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
