.icon-col {
  margin: 12rem 0 16rem;

  @include tablet {
    margin: 8rem 0;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 50%;

    @include tablet {
      width: 100%;
      margin: 0 0 4rem;
      padding: 0 0 4rem;
      border-bottom: 0;
    }

    &:nth-child(1) {
      padding-right: 11.4rem;
      border-right: 0.1rem solid var(--black);

      @include tablet {
        padding-right: 0;
        border-right: 0;
      }
    }

    &:nth-child(2) {
      padding-left: 11.4rem;

      @include tablet {
        padding-left: 0;
      }
    }

    &:nth-last-child(1) {
      @include tablet {
        padding: 0;
        border-bottom: none;
        margin: 0;
      }
    }

    img {
      margin: 0 0 4rem;
      height: 9.6rem;
      width: auto;

      @include extra-large{
        height: 14rem;
      }

      @include mobile {
        height: 8rem;
        margin: 0 0 3.2rem;
      }
    }

    h4 {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.4rem;

      @include extra-large{
        font-size: 2.4rem;
        line-height: 3.6rem;
      }

      @include mobile {
        font-size: 1.4rem;
        line-height: 2.4rem;
      }
    }

    h3 {
      font-size: 2.8rem;
      font-weight: 500;
      line-height: 4rem;
      margin: 0.8rem 0 0;

      @include extra-large{
        font-size: 3.6rem;
        line-height: 6rem;
        margin: 1.6rem 0 0;
      }

      @include mobile {
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 3.2rem;
      }
    }

    p {
      margin: 2.4rem 0 0;

      @include extra-large{
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }

    &__buttons {
      margin: 4rem 0 0;

      @include mobile {
        margin: 0;
      }

      a {
        margin: 2.4rem 2.4rem 0 0;

        @include mobile {
          margin: 2.4rem 1.6rem 0 0;
        }
      }
    }
  }
}
