.label {
  display: inline-flex;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
  align-items: center;

   @include extra-large{
    font-size: 2rem;
    line-height: 2.8rem;
   }

  svg, img {
    margin: 0 0.8rem 0 0;
    width: 1.6rem;
    height: auto;

    @include extra-large{
      margin: 0  1.2rem 0 0;
      width: 2.4rem;
    }
  }
}
