.kalypso {
  background: #eaeaf7;
  padding: 11rem 9.7rem 12rem 12rem;

  &__video {
    &__container {
      margin: 1.2rem 0;
      display: flex;
      justify-content: center;
    }
    width: 50rem;
  }
  &__title {
    &__container {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      justify-content: center;
    }
    color: #070a21;
    text-align: center;

    font-family: Poppins;
    font-size: 4.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 6.4rem;
  }

  &__features {
    &__container {
      display: flex;
      align-items: center;
      gap: 1.6rem;
    }
    &__item {
      display: flex;
      padding: 3.2rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.2rem;
      border-radius: 1.2rem;
      border: 1px solid #f8f8f8;
      background: #f5f6fe;
      &__title {
        color: #131313;
        font-family: Poppins;
        font-size: 1.9rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.6rem;
      }
      &__description {
        color: #606060;
        font-family: Poppins;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;
      }
    }
  }
  &__cta {
    margin-top: 9.1rem;
    text-align: center;
  }
}

.kalypso__description {
  color: #4b4d66;
  text-align: center;
  font-family: Inter;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30.96px;
}
