@import '../../node_modules/swiper/swiper.scss';

:root {
  --black: #070A21;
  --dark: #2B2F40;
  --black2: #24294E;
  --blue: #424CEA;
  --grey: #F3F7FA;
  --white: #FFFFFF;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 1.6rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}


::-moz-selection {
  /* Code for Firefox */
  color: var(--white);
  background: var(--blue);
}

::selection {
  color: var(--white);
  background: var(--blue);
}

html {
  font-size: 62.5%;
}

.container {
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 4rem;

  @include extra-large {
    max-width: 180rem;
  }

  @include tablet {
    padding: 0 60px;
  }

  @include mobile {
    padding: 0 2.4rem;
  }
}

main {
  overflow: hidden;
  background-color: var(--white);
}

button {
  cursor: pointer;
}

.table-wrap {
  overflow: auto;
  table {
    th,td {
      padding: 2.4rem 4rem;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      vertical-align: middle;
    }

    th {
      font-weight: bold;
    }

    tr {
      th,td {
        &:nth-child(1) {
          text-align: left;
        }
      }
    }

    tr:nth-child(odd) {

      th,td {
        background-color: #F5F6FE;
        &:nth-child(1) {
          border-top-left-radius: 0.8rem;
          border-bottom-left-radius: 0.8rem;
        }

        &:nth-last-child(1) {
          border-top-right-radius: 0.8rem;
          border-bottom-right-radius: 0.8rem;
        }
      }
    }
  }
}
