.not-found {
  padding: 19.2rem 0 6.4rem;
  background-color: #F2F2F9;
  min-height: calc(100vh - 28.15rem);
  display: flex;
  align-items: center;

  @include mobile {
    padding: 16.5rem 0 10.6rem;
  }

  img {
    max-width: 58.8rem;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }

  h1 {
    margin: 3.2rem 0 0;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.2rem;

    @include mobile {
      margin: 4.8rem 0 0;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin: 3.2rem 0 0;
  }
}
