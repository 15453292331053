.main-hero {
  height: 100dvh;
  display: flex;
  align-items: end;
  padding: 0 0 64px 0;
  position: relative;

  @include mobile {
    padding: 0;
    height: 100svh;
  }

}
.main-hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  background-image: url('/newsite/images/content/poster.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  @include mobile-xs{
    object-fit: cover;
    background-image: url('/newsite/images/content/poster-mobile.webp');
  }
}
.main-hero__container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
  z-index: 3;
  padding: 0 4rem;

  @include mobile{
    padding: 0 12px;
  }
  
  @include extra-large {
    max-width: 180rem;
  }
}
.main-hero__title {
  font-family: Poppins;
  font-size: 68px;
  font-weight: 500;
  line-height: 80px;
  text-align: left;
  margin-bottom: 4.8rem;
  width: 100%;

  @include extra-large {
    font-size: 108px;
    line-height: 120px;
  }

  @include tablet {
    font-size: 60px;
    line-height: 90px;
  }

  @include mobile {
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    margin-bottom: 24px;

    > br {
      display: none;
    }
  }
}
.main-hero__content {
  display: flex;
  justify-content: space-between;
  align-content: center;
  border-top: 2px solid rgb(14, 0, 0);
  padding-top: 2.7rem;

  @include tablet {
    flex-direction: column;
  }

  @include mobile {
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
  }
}
.main-hero__subtitle {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 430px;
  color: #24294e;

  @include extra-large {
    font-size: 28px;
    max-width: 590px;
    line-height: 38px;
  }

  @include tablet {
    width: 100%;
  }

  @include mobile {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
  }
}
.main-hero__cta {
  @include tablet {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 2.7rem 0 0 0;
  }

  @include mobile {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 32px;
    width: 100%;
  }
  .btn {
    width: 100%;
  }
}

video.main-hero-video {
  position: absolute;
  z-index: -1;
  height: 100%;
} 



#video-container {
  display: none;

  @include mobile {
    display: block;
    z-index: 1;
    height: 100%;
    width: 100vw;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
}
