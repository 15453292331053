.frameworks {
  width: 100lvw;
  background-color: #f5f6fe;
  border-radius: 32px 32px 0px 0px;
  position: relative;

  &__imgbox {
    position: absolute;
    top: -61px;
    right: 76px;
    img {
      object-fit: cover;
    }
    @include large {
      right: 120px;
    }
    @include tablet {
      right: -56px;
       img {
        width: 250px;
        height: 250px;
      }
    }
    @include mobile {
      right: -76px;
       img {
        width: 154px;
        height: 154px;
      }
    }

  }

  &__minhead {
    display: flex;
    gap: 8px;
    padding-top: 120px;

    @include extra-large {
      padding-top: 240px;
    }
    
    @include mobile {
      padding-top: 80px;
    }

    > p {
      font-size: 12px;
      font-weight: 600;
      @include extra-large {
        font-size: 20px;
      }
    }
  }
  &__heading {
    font-size: 48px;
    line-height: 64px;
    font-weight: 500;
    color: #070a21;
    padding-top: 24px;
    padding-bottom: 32px;
    display: inline-block;
    width: 56%;

    @include mobile {
      font-size: 32px;
      line-height: 40px;
      padding: 24px 0px;
      width: 100%;
    }
    @include extra-large {
      font-size: 68px;
      padding-bottom: 48px;
      line-height: 80px;
    }
  }
  &__subhead {
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    color: #4b4d66;
    padding-bottom: 64px;

    @include extra-large {
      font-size: 36px;
      padding-bottom: 84px;
    }

    @include mobile {
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 40px;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    padding-bottom: 100px;

    @include extra-large {
      padding-bottom: 200px;
    }

    @include tablet {
      align-items: normal;
      flex-direction: column-reverse;
      padding-bottom: 40px;
    }

    &__flexwrap {
      display: flex;
      flex-direction: row;
      align-items: center;

      @include tablet {
        flex-direction: column;
      }
    }

    &__nav {
      margin: 0 90px 0 0;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 10;

      @include tablet {
        flex-direction: row;
        gap: 24px;
        margin: 32px 0 0 0;
        width: 100%;
        max-width: 564px;
      }

      .after-effect {
        color: var(--blue);
        &::after {
          transform: scaleX(1);
        }
      }
      > a {
        margin-bottom: 32px;
        font-size: 24px;
        font-weight: 500;
        color: #2B2F40;
        cursor: pointer;
        position: relative;
        width: fit-content;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -0.8rem;
          height: 3px;
          width: 100%;
          border-radius: 1rem;
          background-color: var(--blue);
          transform: scaleX(0);
          transform-origin: left;
          @include transition(transform);
        }

        @include tablet {
          margin-bottom: 0;
          font-size: 20px;
          line-height: 32px;
        }

        @include extra-large {
          font-size: 32px;
        }


      }
    }

    &__content {
      margin-right: 126px;
      min-width: 320px;
      z-index: 10;
      opacity: 1;
      animation: slide-in 0.6s ease-in;

      @keyframes slide-in {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      &.display-none {
        display: none;
      }

      @include tablet {
        margin-right: 0;
        margin-top: 48px;
        min-width: none;
        width: 100%;
        max-width: 564px;
      }

      &__head {
        font-size: 20px;
        padding-bottom: 16px;
        font-weight: 500;
        color: #070A21;

        @include extra-large {
           font-size: 32px;
           padding-bottom: 24px;
        }

        @include mobile {
          font-size: 18px;
        }
      }
      &__subhead {
        font-size: 16px;
        padding-bottom: 40px;
        font-weight: 400;
        color: #373C51;
        line-height: 24px;
        width: 92%;

        @include extra-large {
          font-size: 24px;
          line-height: 32px;
          width: 97%;
        }

        @include mobile {
          font-size: 14px;
          width: 100%;
        }

        > br {
          @include tablet {
            display: none;
          }
        }
      }
    }
  }
}

.framework-slides {
  padding: 8rem 0;
  margin: 12rem 0 8rem;
  background-color: #424cea;
  &__slider {
    overflow: visible;
    width: 100%;
    height: fit-content;
    position: relative;
    max-width: 564px;
    
    @include extra-large {
      max-width: 900px;
    }


    &::before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      width: 100lvw;
      height: 100%;
      background-color: #f5f6fe;

      transform: rotate(180deg);
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      left: 100%;
      top: 0;
      width: 100vw;
      height: 100%;
      background: linear-gradient(
        270deg,
        #f5f6fe 0%,
        rgba(245, 246, 254, 0) 191.3%
      );
      z-index: 1;
    }
  }

  &__item {
    height: fit-content;
    background-color: var(--white);
    border-radius: 0.8rem;
    margin-right: 24px;

    &__desktopImg {
      height: 450px;
      @include tablet {
        height: 700px;
      }
      > video {
        border-radius: 0.8rem;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

video#framework_build_video {
  position: absolute;
  z-index: -1;
  height: unset;
}

#framework-build-video-container {
  display: none;

  @include mobile {
    display: block;
    z-index: 1;
    height: 100%;
    width: 100vw;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url('/newsite/images/video/Build_Mobile_opt.mp4');
  }
}