.oracle {
  &__wrap {
    margin: 9rem 0 16rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: start;

    &__left {
      h2 {
        margin: 2.4rem 0 0;
        @include extra-large {
          font-size: 8rem;
          margin: 4.2rem 0 0;
          padding-bottom: 2rem;
        }
      }
    }
  }

  &__item__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
    margin: 5rem 0 0;
  }

  &__item {
    display: flex;
    flex: 1;
    min-width: 560px;
    height: 219px;
    gap: 2rem;
    padding: 3.2rem;
    background-color: var(--white);
    border-radius: 0.8rem;

    @include extra-large{
      min-width: 780px;
      padding: 4.6rem;
      height: fit-content;
      gap: 4rem;
      border-radius: 1.2rem;
    }

    @include mobile {
      height: auto;
      min-width: 300px;
    }

    &:nth-child(3n + 1) {
      background-color: #f5f6fe;
    }

    &:nth-child(3n + 2) {
      background-color: #eaeaf7;
    }

    &:nth-child(3n + 3) {
      background-color: #e0e1f9;
    }

    &__left {
      display: flex;
      align-items: flex-start;

      &__img {
        width: 74px;
        @include extra-large{
          width: 98px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: start;

      span {
        outline: 1px solid black;
        border-radius: 31px;
        padding: 6px 12px;
        margin: 0 0 1.6rem;
        font-size: 1rem;
        font-weight: 600;
        @include extra-large{
          font-size: 2.8rem;
          padding: 8px 16px;
        }
      }

      h3 {
        font-size: 2.4rem;
        font-weight: 500;
        margin: 0 0 1.6rem 0;

        @include extra-large{
          font-size: 3.2rem;
          margin: 2rem 0 3.6rem 0;
          line-height: 3.6rem;
        }

        @include mobile {
          font-size: 1.8rem;
          line-height: 2.4rem;
        }
      }

      p {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 0;

        @include extra-large{
          font-size: 2.4rem;
          line-height: 3rem;
        }

        @include mobile {
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
    }
  }
}

.no-content {
  height: auto !important;
  .oracle__item__right {
    justify-content: center !important;
    h3 {
      margin-bottom: 0;
    }
  }
}

.gateway_slides_wrap {
  background-color: transparent;
  .slides-blue__item {
    background-color: white !important;
  }
}

.gateways {
  display: none;
}
@include mobile {
  .gateways {
    display: block;
  }
}

.frontends {
  display: none;
}
@include tablet {
  .frontends {
    display: block;
  }
}

.gateway__item {
  display: flex;
  flex-direction: column;
  height: min-content;
  width: 100%;
  min-width: 100%;
  padding: 0;
  background-color: transparent !important;
  img {
    margin-bottom: 2.4rem !important;
  }
}

.gateways-slider-buttons {
  display: none;
}

@include mobile {
  .gateways-slider-buttons {
    margin: 2.4rem 0 2.4rem 0;
    display: block;
  }
}

.frontends-slider-buttons {
  display: none;
}

@include tablet {
  .frontends-slider-buttons {
    margin: 2.4rem 0 2.4rem 0;
    display: block;
  }
}

.gateways__desktop {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  margin-top: 8rem;
  @include tablet {
    grid-template-columns: 1fr 1fr;
  }
  @include mobile {
    display: none;
  }
  & > div {
    width: 100%;
    padding: 3.2rem;
    border-radius: 0.8rem;
    background-color: #ffffff;
  }
}

.frontends__desktop {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(6, 1fr);
  gap: 32px;
  margin-top: 8rem;
  @include tablet {
    display: none;
  }
  & > div {
    width: 100%;
    padding: 3.2rem;
    border-radius: 0.8rem;
    background-color: #ffffff;
  }

  /* First row items: each taking up 2 of the 6 columns (effectively creating 3 columns) */
  .first-row {
    grid-column: span 2;
  }
}
