.slides {
  padding: 8rem 0;
  margin: 12rem 0 8rem;
  background-color: #e0e1f9;

  @include mobile {
    margin: 8rem 0;
    padding: 4rem 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      h2 {
        margin: 2.4rem 0 0;
      }
    }
  }

  &__slider {
    overflow: visible;
    margin: 8rem 0 0;
    position: relative;

    @include mobile {
      margin: 4rem 0 0;
    }

    &::after {
      content: "";
      position: absolute;
      left: 103%;
      top: 0;
      width: 20vw;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(224, 225, 249, 0) 0%,
        #e0e1f9 60%
      );
      z-index: 1;
      @include tablet {
        left: 100%;
      }
    }

    &::before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      width: 20vw;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(224, 225, 249, 0) 0%,
        #e0e1f9 60%
      );
      transform: rotate(180deg);
      z-index: 2;
    }
  }

  &__item {
    height: auto;
    padding: 3.2rem;
    background-color: var(--white);
    border-radius: 0.8rem;

    @include mobile {
      padding: 2.4rem;
    }

    img {
      height: 4rem;
      width: auto;
      margin: 0 0 4rem;

      @include mobile {
        height: 3.6rem;
        margin: 0 0 3.2rem;
      }
    }

    p {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
    }
  }
}

.slides-blue {
  padding: 8rem 0;
  margin: 12rem 0 8rem;
  background-color: #424cea;

  @include extra-large{
    padding: 12rem 0;
    margin: 16rem 0 12rem;
  }

  @include mobile {
    margin: 8rem 0;
    padding: 4rem 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
    padding-bottom: 3rem;
    border-bottom: 1px solid var(--white);

    &__left {
      h2 {
        margin: 2.4rem 0 0;
        @include extra-large {
          font-size: 8rem;
          margin: 4.2rem 0 0;
          padding-bottom: 2rem;
        }
      }
    }
  }

  &__slider {
    overflow: visible;
    margin: 8rem 0 0;
    position: relative;

    @include mobile {
      margin: 4rem 0 0;
    }

    &::after {
      content: "";
      position: absolute;
      left: 100%;
      top: 0;
      width: 20vw;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(224, 225, 249, 0) 0%,
        #424cea 60%
      );
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      width: 20vw;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(224, 225, 249, 0) 0%,
        #424cea 60%
      );
      transform: rotate(180deg);
      z-index: 2;
    }
  }

  &__item {
    height: auto;
    padding: 3.2rem;
    background-color: var(--white);
    border-radius: 0.8rem;

    &:nth-child(3n + 1) {
      background-color: #f5f6fe;
    }

    &:nth-child(3n + 2) {
      background-color: #eaeaf7;
    }

    &:nth-child(3n + 3) {
      background-color: #e0e1f9;
    }

    @include mobile {
      padding: 2.4rem;
    }

    img {
      height: 4rem;
      width: auto;
      margin: 0 0 4rem;

      @include extra-large{
        height: 8rem;
      margin: 0 0 6rem;

      }

      @include mobile {
        height: 3.6rem;
        margin: 0 0 3.2rem;
      }
    }

    h3 {
      font-size: 2.4rem;
      font-weight: 500;
      margin: 0 0 1.6rem 0;

      @include extra-large{
        font-size: 3.2rem;
        margin: 2rem 0 3.6rem 0;
        line-height: 4.8rem;
      }

      @include mobile {
        font-size: 1.8rem;
        line-height: 2.4rem;
      }
    }

    p {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      @include extra-large{
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }
  }
}
