.icon-list {
  margin: 12rem 0;

  @include tablet {
    margin: 8rem 0;
  }

  @include mobile {
    margin: 4rem 0;
  }

  &__container {
    padding: 12rem 0;
    border-top: 0.1rem solid var(--black);
    border-bottom: 0.1rem solid var(--black);

    @include tablet {
      padding: 8rem 0;
    }

    @include mobile {
      padding: 4rem 0;
    }
  }

  &__wrap {
    margin: 8rem 0 0;
    display: flex;

    @include tablet {
      flex-wrap: wrap;
    }

    @include mobile {
      margin: 4rem 0 0;
    }
  }

  &__left {
    width: 49%;

    @include tablet {
      width: 100%;
      order: 2;
      margin: 4rem 0 0;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__right {
    width: 51%;
    padding: 0 0 0 6rem;
    display: flex;
    height: auto;
    flex-direction: column;

    

    @include tablet {
      width: 100%;
      padding: 0;
    }

    &__button {
      padding: 2.4rem 0 0 6.4rem;
      margin-top: auto;

      @include tablet {
        padding: 0;
        margin: 4rem 0 0;
      }
    }
  }

  &__item {
    display: flex;
    margin: 0 0 3.6rem;

    @include extra-large{
      gap: 4rem;
    }

    @include tablet {
      margin: 0;
      padding: 2rem 0;
      // border-bottom: 0.1rem solid var(--black);
      flex-direction: column;
    }

    &:nth-last-child(1) {
      margin: 0;
    }

    &:nth-child(1) {
      @include tablet {
        padding-top: 0;
      }
    }

    &:nth-last-child(1) {
      @include tablet {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    &__left {
      flex: none;
      margin: 0 2.4rem 0 0;

      @include tablet {
        margin: 0 0 2.4rem;
      }

      img {
        height: 4rem;
        width: auto;

        @include extra-large{
          height: 6rem;
        }

        @include mobile {
          height: 3.2rem;
        }
      }
    }

    &__right {

      h5{
        @include extra-large{
          font-size: 3.2rem;
          line-height: 4rem;
        }
      }

      p {
        margin: 1.6rem 0 0;
        @include extra-large{
          font-size: 2.4rem;
          line-height: 3.2rem;
        }
      }
    }
  }
}
