.unavailable {
  padding: 15.2rem 0 7rem;
  background-color: #f2f2f9;
  min-height: calc(100vh - 28.15rem);
  display: flex;
  align-items: center;

  @include mobile {
    padding: 17.9rem 0 12rem;
  }

  &__wrap {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include tablet {
      flex-direction: column;
    }
  }

  &__left {
    width: 100%;
    position: relative;
    max-width: 79.2rem;

    &__image {
      img {
        width: 100%;
        height: auto;
      }
    }

    @include tablet {
      flex: none;
      width: 100%;
    }
  }

  &__right {
    width: 100%;
    max-width: 58.8rem;
    text-align: center;

    @include tablet {
      width: 100%;
      padding: 0;
      text-align: center;
      margin: 1.6rem 0 0;
    }

    h1 {
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 3.2rem;

      @include mobile {
        font-size: 2rem;
        line-height: 3.2rem;
      }
    }
  }
}
