p {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: var(--black2);

  @include mobile {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

h1 {
  font-size: 6.4rem;
  font-weight: 500;
  line-height: 7.2rem;

  @include mobile {
    font-size: 4rem;
    line-height: 5.2rem;
  }
}

h2 {
  font-size: 4.8rem;
  font-weight: 500;
  line-height: 5.6rem;

  @include mobile {
    font-size: 3.2rem;
    line-height: 4rem;
  }
}

h3 {
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 4rem;
}

h4 {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.2rem;

  @include mobile {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
}

h5 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 3.2rem;

  @include mobile {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }
}
