.icon-blocks-with-header {
  margin: 16rem 0;

  @include tablet {
    margin: 8rem 0 16rem 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.1rem solid var(--black);
    padding: 0 0 4.8rem;

    @include tablet {
      flex-direction: column;
      align-items: flex-start;
    }

    @include mobile {
      padding: 0 0 4rem;
    }

    &__left {
      h2 {
        margin: 2.4rem 0 0;
      }
    }

    &__right {
      p {
        @include extra-large {
          font-size: 2.4rem;
          line-height: 3.2rem;
        }
      }

      @include tablet {
        margin: 2.4rem 0 0;
      }
    }
  }

  &__blocks {
    display: flex;
    flex-wrap: wrap;
    margin: 4.8rem -1.2rem -2.4rem;

    @include extra-large{
      margin: 5.6rem -1.2rem -2.4rem;
    }

    @include mobile {
      margin: 4rem -1.2rem -2.4rem;
    }
  }

  &__block {
    width: 33.3333%;
    padding: 0 1.2rem;
    height: auto;
    margin: 0 0 2.4rem;

    @include extra-large{
      padding: 0 1.6rem;
    }

    @include tablet {
      width: 100%;
    }

    &:nth-child(3n + 1) {
      .icon-blocks-with-header__block__wrap {
        background-color: #f5f6fe;
      }
    }

    &:nth-child(3n + 2) {
      .icon-blocks-with-header__block__wrap {
        background-color: #eaeaf7;
      }
    }

    &:nth-child(3n + 3) {
      .icon-blocks-with-header__block__wrap {
        background-color: #e0e1f9;
      }
    }

    &__wrap {
      background-color: #ecedfd;
      height: 100%;
      padding: 4rem;
      border-radius: 0.8rem;

      @include mobile {
        padding: 2.4rem;
      }

      p {
        margin: 2.4rem 0 0;
        padding: 0 1.6rem 0 0;

        @include extra-large{
          font-size: 2.4rem;
          line-height: 3.2rem;
          padding: 0 2.4rem 0 0;
        }

        @include mobile {
          padding: 0;
          margin: 1.6rem 0 0;
        }
      }

      h3 {

        @include extra-large{
          font-size: 3.6rem;
          line-height: 4.2rem;
        }

        @include mobile {
          font-size: 1.8rem;
          line-height: 2.4rem;
        }
      }

      img {
        margin: 0 0 5.2rem;
        height: 9.6rem;
        width: auto;

        @include mobile {
          margin: 0 0 3.2rem;
          height: 8rem;
        }
      }
    }
  }
}
