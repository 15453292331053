.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  @include tablet {
    position: fixed;
  }

  &.is-scrolled {
    .header__main {
      transform: translateY(-100%);
      box-shadow: none;
    }
  }

  &__top {
    width: 100%;
    padding: 2.4rem 0;
    background-color: #fff;

    @include tablet {
      display: none;
      height: 0.1rem;
    }

    &__text {
      &__item {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.2rem;
        display: flex;
        align-items: center;
        color: var(--black);

        svg {
          margin: 0 0 0 0.8rem;
        }
      }
    }

    &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__socials {
      display: flex;

      a {
        margin: 0 2.4rem 0 0;
        display: flex;

        &:nth-last-child(1) {
          margin: 0;
        }

        svg {
          height: 1.6rem;
          width: auto;

          path {
            @include transition(fill);
          }

          &:hover {
            path {
              fill: var(--blue);
            }
          }
        }
      }
    }
  }

  &__main {
    padding: 3.2rem 0;
    @include transition(transform, background-color, box-shadow);

    // @include tablet {
    //   background-color: #fff;
    // }

    @include mobile {
      padding: 1.6rem 0;
    }

    @include extra-large {
      padding: 4.8rem 0;
    }

   
    &.is-hovering {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      box-shadow: 0px 4px 40px 0px rgba(43, 47, 64, 0.08);
      background-color: #fff;

    }
    &.is-fixed {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
    }
    &.bg-white{ 
      background-color: #fff;
      box-shadow: 0px 4px 40px 0px rgba(43, 47, 64, 0.08);
    }

    &.is-mobile-menu-active {
      box-shadow: none !important;
    }

    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__lang {
      position: relative;
      margin: 0 0 0 3.2rem;

      @include tablet {
        margin: 0 2.4rem 0 0;
      }

      &:hover {
        .header__main__lang__tooltip {
          display: block;
        }

        .header__main__lang__current {
          svg {
            transform: rotate(180deg);
          }
        }

        > a {
          &::after {
            transform: scaleX(1);
          }
        }
      }

      &__current {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.4rem;
        display: flex;
        align-items: center;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;

        svg {
          flex: none;
          margin: 0 0 0 0.4rem;
          @include transition(transform);
        }
      }

      &__tooltip {
        position: absolute;
        padding: 0.8rem 1.6rem;
        border-radius: 0.8rem;
        background-color: #fff;
        text-align: center;
        left: 50%;
        transform: translate(-50%, 0%);
        top: calc(100% + 2rem);
        display: none;
        filter: drop-shadow(0px 1px 8px rgba(43, 47, 64, 0.08));

        @include mobile {
          padding: 0.8rem;
          padding-bottom: 0;
        }

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          top: 0;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 0 0.7rem 1.1rem 0.7rem;
          border-color: transparent transparent #fff transparent;
          transform: rotate(0deg) translateX(-50%) translateY(-100%);
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: -2rem;
          height: 2rem;
          width: 100%;
          background-color: transparent;
        }

        a {
          display: block;
          width: 100%;
          text-align: center;
          padding: 0.8rem 0.5px;
          border-bottom: 0.1rem solid #eee;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.8rem;
          color: #070a21;

          @include mobile {
            padding: 0.8rem 0.9rem;
          }

          &.is-active {
            color: var(--blue);
          }

          &:nth-last-child(1) {
            border: none;
          }
        }

        &__desktop {
          font-size: 1.2rem;
          line-height: 1.8rem;
          @include mobile {
            display: none;
          }
        }

        &__mobile {
          display: none;
          @include mobile {
            display: block;
          }
        }
      }
    }

    &__burger {
      height: 2.2rem;
      width: 2.2rem;
      padding: 0.4rem 0;
      flex-direction: column;
      justify-content: space-between;
      border: none;
      background-color: transparent;
      display: none;

      @include tablet {
        display: flex;
      }

      &.is-active {
        span {
          &:nth-child(1) {
            transform: translateY(0.65rem) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translateY(-0.65rem) rotate(-45deg);
          }
        }
      }

      span {
        width: 100%;
        height: 0.1rem;
        background-color: #070a21;
        @include transition(opacity, transform);
        transform-origin: center center;
      }
    }

    &__left {
      a {
        display: inline-flex;
        svg {
          display: flex;
          width: 12.3rem;

          @include extra-large {
            width: 18rem;
            height: auto;
          }

          @include mobile {
            width: 10.9rem;
          }
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
    }

    &__button {
      margin: 0 0 0 3.2rem;


      &--items {
        display: flex;
        align-items: center;
        gap: 3.2rem;

        > *:not(:last-child) {
          color: var(--black);
          &:hover {
            color: var(--black);
          }
        }
      }

      @include tablet {
        display: none;
      }

      a {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.8rem;
        color: var(--blue);
        display: flex;
        align-items: center;
        @include transition(color);

        &:hover {
          color: #313aca;

          svg {
            path {
              fill: #313aca;
            }
          }
        }

        svg {
          margin: 0 0 0 0.8rem;

          path {
            @include transition(fill);
          }
        }

        @include extra-large {
          font-size: 28px;
        }
      }
    }

    &__nav {
      display: flex;

      @include tablet {
        display: none;
      }

      > li {
        margin: 0 3.2rem 0 0;

        @include extra-large{
          margin: 0 4.8rem 0 0;
        }

        &:nth-last-child(1) {
          margin: 0;
        }
       

        span {
          color: var(--black);
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 2.4rem;
          position: relative;

          @include extra-large{
            font-size: 2.6rem;
            line-height: 4.8rem;
          }

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -0.4rem;
            height: 0.1rem;
            width: 100%;
            background-color: var(--blue);
            transform: scaleX(0);
            transform-origin: left;
            @include transition(transform);
          }
        }
      }

      &__item {
        position: relative;
        &:nth-child(2),
        &:nth-child(3) {
          .header__main__nav__item__submenu {
            @include large {
              left: 80%;
              transform: translate(-80%, 0%);
            }
            &::before {
              @include large {
                left: 80%;
                transform: rotate(0deg) translateX(-220%) translateY(-100%);
              }
            }
          }
        }

        &:hover {
          .header__main__nav__item__submenu {
            display: block;
          }

          > a {
            &::after {
              transform: scaleX(1);
            }
          }
        }

        &__submenu {
          position: absolute;
          padding: 1.6rem 3.2rem 3.2rem;
          background-color: #fff;
          width: 69.6rem;
          left: 50%;
          transform: translate(-50%, 0%);
          border-radius: 0.8rem;
          top: calc(100% + 2rem);
          display: none;
          filter: drop-shadow(0px 0px 40px rgba(43, 47, 64, 0.08));

          @include extra-large{
            padding: 2.6rem 4.8rem 4.8rem;
            width: 100rem;
          }

          &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 0;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 0 0.7rem 1.1rem 0.7rem;
            border-color: transparent transparent #fff transparent;
            transform: rotate(0deg) translateX(-50%) translateY(-100%);
          }

          &--offset {
            left: auto;
            right: 0;
            transform: translate(10rem, 0%);

            &::before {
              right: 0;
              left: auto;
              transform: rotate(0deg) translateX(-11.5rem) translateY(-100%);
            }
          }

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: -2rem;
            height: 2rem;
            width: 100%;
            background-color: transparent;
          }

          &__wrap {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -2rem;
          }

          &__item {
            width: 50%;
            padding: 0 2rem;
            height: auto;

            &:nth-child(1),
            &:nth-child(2) {
              a {
                border-top: none;
              }
            }

            a {
              padding: 1.6rem 0;
              display: flex;
              align-items: center;
              border-top: 0.1rem solid #eee;
              height: 100%;

              img {
                flex: none;
                width: 4rem;
                height: auto;
                margin: 0 1.6rem 0 0;
                @include extra-large{
                  width: 4.8rem;
                  margin: 0 2.4rem 0 0;
                }
              }

              &:hover {
                h3 {
                  color: var(--blue);
                }
              }
            }

            &__content {
              h3 {
                color: var(--black);
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 2.4rem;
                @include transition(color);

                @include extra-large{
                  font-size: 2.4rem;
                  line-height: 3.2rem;
                }
              }

              p {
                font-size: 1.2rem;
                font-weight: 400;
                line-height: 1.8rem;
                margin: 0.8rem 0 0;

                @include extra-large{
                  font-size: 1.8rem;
                  line-height: 2.4rem;
                }

              }
            }
          }
        }
      }
    }
  }
}

.menu-mobile {
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 99;
  display: none;
  transform: translateY(-100%);

  @include tablet {
    display: none;
  }

  .container {
    height: 100%;
    display: flex;
  }

  &__wrap {
    height: 100%;
    padding: 7.4rem 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    //overflow: auto;
  }

  &__main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__content {
      flex: 1;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;

      &__button,
      &__link {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.6rem 0;
        border: none;
        border-bottom: 0.1rem solid #eeeeee;
        background-color: transparent;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 2.4rem;
        color: #070a21;
      }

      &__link {
        color: var(--blue);
        &-black {
          color: var(--black);
        }
      }
    }

    &__bottom {
      margin: auto 0 0;

      &__text {
        padding: 1.6rem 0;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          width: 100vw;
          height: 0.1rem;
          background-color: #eeeeee;
        }

        &__item {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 2.4rem;
          display: flex;
          align-items: center;
          color: var(--black);

          svg {
            margin: 0 0 0 0.8rem;
          }
        }
      }

      &__content {
        padding: 1.6rem 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        &__left {
          &__button {
            a {
              font-size: 1.6rem;
              font-weight: 500;
              line-height: 1.8rem;
              color: var(--blue);
              display: flex;
              align-items: center;

              svg {
                margin: 0 0 0 0.8rem;
              }
            }
          }
        }

        &__right {
          width: 100%;
          &__socials {
            width: 100%;
            flex-wrap: wrap;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: 2.4rem 3.7rem;

            &__item {
              display: flex;
              //margin: 0 0 2.4rem;
              justify-content: center;

              &:nth-child(6n + 1) {
                justify-content: center;
              }
              &:nth-child(6n + 6) {
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }

  &__submenus {
    display: none;
    &__item {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 10rem;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(255, 255, 255, 1) 78%
        );
      }
      &__button {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1.6rem 0 0;
        border: none;
        background-color: transparent;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 2.4rem;
        color: #070a21;

        svg {
          // transform: rotate(180deg);
          margin: 0 1.6rem 0 0;
        }
      }

      &__list {
        margin: 0.8rem 0 0;
        overflow: auto;
        height: 80vh;
        padding-bottom: 10rem;

        li {
          &:nth-last-child(1) {
            a {
              border-bottom: 0;
            }
          }
          a {
            padding: 1.6rem 0;
            display: flex;
            flex-direction: column;
            border-bottom: 0.1rem solid #eee;
            h3 {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              color: #070a21;
            }

            p {
              font-size: 1.2rem;
              font-weight: 400;
              line-height: 1.8rem;
              color: #24294e;
              margin: 0.8rem 0 0;
            }
          }
        }
      }
    }
  }
}

.display-none {
  display: none;
}
