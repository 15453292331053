.faq {
  margin: 8rem 0;

  &__header {
    text-align: center;

    h2 {
      margin: 2.4rem 0 0;
    }

    p {
      margin: 2.4rem 0 0;
    }
  }

  &__items {
    margin: 5.2rem 0 0;

    @include mobile {
      margin: 1.6rem 0 0;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin: 5.6rem 0 0;

    @include mobile {
      margin: 4rem 0 0;
      flex-direction: column;
      align-items: center;
    }

    a {
      margin: 0 3.2rem 0 0;

      @include mobile {
        margin: 0 0 2.4rem;

        &:nth-last-child(1) {
          margin: 0;
        }
      }
    }
  }
}
