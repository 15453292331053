.btn {
  display: inline-flex;
  padding: 0.4rem 2.4rem;
  border-radius: 10rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 3.2rem;
  font-family: "Poppins", sans-serif;
  color: var(--white);
  background-color: rgba(#fff, 0.2);
  justify-content: center;
  @include transition(background);

  &:hover {
    background-color: rgba(#fff, 0.5);
  }

  &--grey {
    background-color: #f3f7fa;
    color: var(--blue);

    &:hover {
      background-color: #e8eff5;
    }
  }

  &--white {
    background-color: #fff;
    color: var(--blue);

    &:hover {
      background-color: #e0e1f9;
    }
  }

  &--blue {
    background-color: var(--blue);
    color: #fff;

    &:hover {
      background-color: #313aca;
    }
  }

  &--big {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem;
    padding: 1.2rem 2.4rem;
    @include extra-large{
      font-size: 2.6rem;
      line-height: 3.2rem;
      padding: 1.8rem 3.2rem;
    }
  }

  &--huge {
    font-size: 2rem;
    line-height: 2.4rem;
    padding: 1.6rem 3.2rem;

    @include mobile {
      padding: 1.2rem 2.4rem;
      font-size: 1.6rem;
    }
  }

  &--bold {
    font-weight: 600;
  }
}

.btn-group {
  display: flex;
  .btn {
    margin: 0;

    &:nth-child(1) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:nth-child(2) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.link {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.8rem;
  display: inline-flex;
  align-items: center;
  color: var(--blue);
  @include transition(color);

  @include extra-large{
    font-size: 2.4rem;
  }

  &:hover {
    color: #313aca;

    svg {
      path {
        fill: #313aca;
      }
    }
  }

  svg {
    margin: 0 0 0 1.2rem;

    @include extra-large{
      height: 2.4rem;
      width: auto
    }

    path {
      @include transition(fill);
    }
  }
}

.slider-nav {
  display: flex;

  &__btn {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--white);
    margin: 0 2rem 0 0;
    user-select: none;
    @include transition(background-color, opacity);

    @include mobile {
      margin: 0 1.6rem 0 0;
    }

    &--grey {
      background-color: #f2f2f9;
    }

    svg {
      @include transition(transform);
    }

    &:nth-last-child(1) {
      margin: 0;
    }

    &:active {
      svg {
        transform: scale(0.8);
      }
    }

    &.swiper-button-disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
    }
  }
}

.center {
  display: flex;
  justify-content: center;
}
