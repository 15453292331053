.tabs {
  margin: 18rem 0 12rem;

  @include tablet {
    margin: 8rem 0;
  }

  .container {
    @include mobile {
      padding: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 2.4rem;
    }

    &__left {
      h2 {
        margin: 2.4rem 0 0;
      }
    }

    &__right {
      max-width: 38.4rem;

      @include mobile {
        margin: 2.4rem 0 0;
      }

      p {
        font-size: 2rem;
        font-weight: 400;
        line-height: 3.2rem;
      }
    }
  }

  &__tabs {
    margin: 8rem 0 0;
    position: relative;

    @include mobile {
      margin: 4rem 0 0;
      display: flex;
      overflow: scroll;
    }

    &__indicator {
      position: absolute;
      height: 0.4rem;
      background-color: var(--blue);
      left: 0;
      bottom: 0.1rem;
      z-index: 1;
      border-radius: 0.2rem;
      @include transition(transform, width);
    }

    &__wrap {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.1rem solid #070A21;

      @include mobile {
        margin: 0 2.4rem;
      }
    }

    &__item {
      font-size: 2rem;
      font-weight: 500;
      line-height: 3.2rem;
      background-color: transparent;
      border: none;
      color: #070A21;
      padding: 0 0 0.8rem;
      position: relative;
      @include transition(color);

      @include tablet {
        font-size: 1.8rem;
      }

      @include mobile {
        white-space: nowrap;
        margin: 0 4.8rem 0 0;

        &:nth-last-child(1) {
          margin: 0;
        }
      }

      &:hover {
        color: var(--blue);
      }

      //&::after {
      //  content: "";
      //  position: absolute;
      //  left: 0;
      //  bottom: 0;
      //  width: 100%;
      //  height: 0.4rem;
      //  background-color: var(--blue);
      //  opacity: 0;
      //  border-radius: 0.2rem;
      //  @include transition(opacity);
      //}

      &.is-active {
        color: var(--blue);

        //&::after {
        //  opacity: 1;
        //}
      }
    }
  }

  &__content {
    margin: 6.4rem 0 0;

    @include tablet {
      margin: 4rem 0 0;
      padding: 0 2.4rem;
    }

    &__item {
      align-items: center;
      display: none;

      @include tablet {
        flex-direction: column;
      }

      &__left {
        width: 48.6rem;
        aspect-ratio: 927/728;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        @include tablet {
          width: 100%;
          order: 2;
          margin: 4rem 0 0;
        }
      }

      &__right {
        flex: 1;
        padding: 0 0 0 12.6rem;

        @include tablet {
          width: 100%;
          flex: none;
          padding: 0;
        }

        h3 {
          font-size: 4rem;
          font-weight: 500;
          line-height: 4.8rem;

          @include mobile {
            font-size: 2.4rem;
            line-height: 3.2rem;
          }
        }
        p {
          margin: 2.4rem 0 0;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 3.2rem;

          @include mobile {
            font-size: 1.4rem;
            line-height: 2.4rem;
          }
        }
        &__btn {
          margin: 3.2rem 0 0;
          display: flex;

          @include mobile {
            margin: 2.4rem 0 0;
          }

          a {
            margin: 0 2.4rem 0 0;
          }
        }
      }
    }
  }
}
