.no__margin {
    margin: 0px !important;
}

.server-config {
    display: flex;
    margin-top: 5rem;
    gap: 8rem;
    align-items: center;

    @include tablet {
        flex-direction: column;
        gap:4rem;
    }

    &__left {
        width: 100%;
        height: 624px;
        background-color: #EAEAF7;
        border-radius: 8px;
        position: relative;
        overflow: hidden ;

        @include tablet{
            max-width: 100%;
            height: 400px;
        }

        &__selector {
            position: absolute;
            top: 0;
            border-radius: 8px;
            background-color: white;
            z-index: 1;
            margin: 2.4rem;
            padding: 1.2rem;
            @include transition(top,height)
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            width: calc(100% - 16px);
            height: 100px;
            background: linear-gradient(0deg, #EAEAF7 0%, rgba(245, 246, 254, 0) 100%);
            z-index: 1;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

            @include mobile{
                height: 50px;
            }
        }

        &__content {
            width: 100%;
            height: 100%;
            overflow-y: auto;
            padding: 2.4rem;
            font-size: 16px;
            padding-bottom: 6rem;
            scroll-behavior: smooth;
            position: relative;
            z-index: 1;


            &__section {
                margin-bottom: 60px;
                padding: 1.2rem;
                border-radius: 8px;

                @include tablet{
                    margin-bottom: 30px;
                }

                p {
                    line-height: 36px;
                    font-family: "Roboto Mono";
                    @include tablet{
                        font-size: 14px;
                        line-height: 26px;
                    }
                }
               @include transition(background-color)
            }


            &::-webkit-scrollbar {
                width: 16px;
                @include tablet{
                    width: 8px;
                }
            }
            &::-webkit-scrollbar-track {
                background: #EAEAF7;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border-left: 1px solid #A7A8B7;
            }
            &::-webkit-scrollbar-thumb {
                background: #A7A8B7;
                @include transition(border-top-right-radius,border-bottom-right-radius);
            }
            &.top::-webkit-scrollbar-thumb {
              border-top-right-radius: 10px;
            }
            
            &.bottom::-webkit-scrollbar-thumb {
              border-bottom-right-radius: 10px;
            }

            

        }
    }

    &__right {
        position: relative;
        height: 624px;
        width: 100%;
        @include tablet{
            height: 400px;
        }

        &__content {
            position: absolute;
            height: 150px;
            top: 30%;

            @include mobile{
                top: 0;
            }

            &.fade-in {
                animation: fadeIn 0.3s ease-in-out forwards;
            }

            &.fade-out {
                animation: fadeOut 0.3s ease-in-out forwards;
            }

            &.display-none {
                display: none;
            }

            &.fade-in-display-none {
                animation: fadeIn-display-none 0.3s ease-in-out forwards;
            }
            &.fade-out-display-none {
                animation: fadeOut-display-none 0.3s ease-in-out forwards;
            }

            @keyframes fadeIn {
                0% {
                    opacity: 0;
                    transform: translateY(-50px);
                }
                
                100% {
                    opacity: 1;
                    transform: translateY(0px);
                }
            }

            @keyframes fadeOut {
                0% {
                    opacity: 0;
                    transform: translateY(50px);
                }
                
                100% {
                    opacity: 1;
                    transform: translateY(0px);
                }
            }

            @keyframes fadeIn-display-none {
                
                0% {
                    opacity: 1;
                    transform: translateY(0px);
                }
                100% {
                    opacity: 0;
                    transform: translateY(50px);
                    display: none;
                }
               
            }

            @keyframes fadeOut-display-none {
                 0% {
                      opacity: 1;
                      transform: translateY(0px);
                }
                100% {
                    opacity: 0;
                    transform: translateY(-50px);
                    display: none;
                }
            }

            h3 {
                font-size: 32px;
                margin-bottom: 2.4rem;
                @include tablet {
                    font-size: 24px;
                    margin-bottom: 1.2rem;
                }
            }
            
            p {
                max-width: 486px;
                @include tablet {
                    font-size: 14px;
                }
                @include mobile {
                    max-width: 312px;
                }
            }
        }

        &__btns {
            position: absolute;
            display: flex;
            top: 30%;
            margin-top: 206px;
            gap: 2.4rem;
            @include mobile{
                top: 0;
            }
            
        }

    }

}