.footer {
  background-color: #070a21;
  color: var(--white);
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  padding: 6rem 0;
  margin: 0;

  @include tablet {
    padding: 4rem 0;
    margin: 0;
  }

  .footer__wrap {
    @include tablet {
      margin: 0;
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;

    @include tablet {
      flex-direction: column;
      margin: -3.5rem 0 0;
    }
  }

  &__left {
    max-width: 32.5rem;

    &__logo {
      img {
        @include extra-large {
          width: 260px !important;
          height: auto;
        }
      }
    }

    @include extra-large {
      max-width: 65rem;
    }

    @include mobile {
      max-width: 100%;
      order: 2;
      display: flex;
      flex-direction: column;
    }

    p {
      margin: 0 0 1.6rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: var(--white);

      @include extra-large {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }

      &:nth-last-child(1) {
        margin: 0;
      }

      @include mobile {
        margin: 0 0 2.4rem;
      }
    }

    &__text {
      margin: 3rem 0 0;

      @include mobile {
        order: 3;
        margin: 3.2rem 0 0;
      }
    }
  }

  &__right {
    display: flex;

    @include tablet {
      margin: 4rem 0 0;
    }

    @include mobile {
      flex-wrap: wrap;
      margin: 0;
    }
  }

  &__item {
    margin: 0 7rem 0 0;

    @include tablet {
      flex: 1;
      margin: 0;
    }

    @include mobile {
      width: 50%;
      flex: none;
      margin: 0 0 4rem;
    }

    &:nth-last-child(1) {
      margin: 0;
    }

    h3 {
      font-size: 1.6rem;
      line-height: 2.4rem;

      @include extra-large {
        font-size: 2.6rem;
        line-height: 4.8rem;
      }
    }

    ul {
      margin: 2.4rem 0 0;

      @include mobile {
        display: flex;
        flex-wrap: wrap;
        margin: 0.8rem 0 0;
      }

      li {
        margin: 1.6rem 0 0;

        @include mobile {
          width: 100%;
          margin: 0.8rem 0 0;
        }
      }

      a {
        color: var(--white);
        font-size: 1.2rem;
        line-height: 1.6rem;

        @include extra-large {
          font-size: 2rem;
          line-height: 3.2rem;
        }
      }
    }
  }

  &__socials {
    margin: 2.4rem 0 0;
    display: flex;

    @include extra-large {
      margin: 4.8rem 0 0;
    }

    @include mobile {
      margin: 3.2rem 0 0;
      justify-content: space-between;
    }

    &__item {
      margin: 0 2.4rem 0 0;

      @include extra-large {
        margin: 0 4.8rem 0 0;
      }

      @include mobile {
        margin: 0;
      }
    }

    a {
      &:nth-last-child(1) {
        margin: 0;
      }

      svg,
      img {
        display: block;
        path {
          @include transition(fill);
        }

        @include extra-large {
          width: 2.8rem;
          height: auto;
        }

        &:hover {
          path {
            fill: var(--blue);
          }
        }
      }
    }
  }

  &__cards {
    display: flex;
    margin: 0 -1.2rem;
    transform: translateY(-11.5rem);

    @include tablet {
      flex-direction: column;
    }
    &__item {
      width: 50%;
      padding: 0 1.2rem;
      height: auto;

      @include tablet {
        width: 100%;
        margin: 0 0 3.2rem;
      }

      &:nth-child(1) {
        .footer__cards__item__wrap {
          background-color: var(--blue);

          img {
            position: absolute;
            right: 0;
            top: 0;
            height: 94%;
            width: auto;

            @include mobile {
              height: 84%;
              transform: translateX(50%);
            }
          }
        }
      }

      &:nth-child(2) {
        .footer__cards__item__wrap {
          background-color: #2db8e3;

          img {
            position: absolute;

            &:nth-of-type(1) {
              right: 11.2rem;
              top: 0;
              height: 65%;
              width: auto;

              @include mobile {
                display: none;
              }
            }

            &:nth-of-type(2) {
              right: 1rem;
              bottom: 0;
              height: 94%;
              width: auto;

              @include mobile {
                right: 0;
                transform: translateX(60%);
                height: 72%;
              }
            }

            &:nth-of-type(3) {
              display: none;

              @include mobile {
                display: block;
                top: 0;
                right: 1.3rem;
                height: 72%;
                width: auto;
              }
            }
          }
        }
      }

      &:nth-last-child(1) {
        @include tablet {
          margin: 0;
        }
      }

      &__wrap {
        padding: 3.2rem 5.6rem;
        border-radius: 0.8rem;
        height: 100%;
        position: relative;
        overflow: hidden;
        z-index: 1;

        @include extra-large{
          padding: 4.6rem 7rem;
        }

        h4{
          @include extra-large{
            font-size: 3.6rem;
            line-height: 4.2rem;
          }
        }

        @include tablet {
          &__desktop {
            display: none;
          }
        }
        &__desktop {
          position: absolute;
          height: 100% !important;
          right: 0;
          bottom: 0;
        }
        &__desktop__network {
          height: 100% !important;
        }
        &__desktop__sdk {
          height: 75% !important;
        }

        img {
          pointer-events: none;
          z-index: -1;
        }

        @include mobile {
          padding: 3.2rem;
        }

        p {
          color: var(--white);
          margin: 1.6rem 0 0;
          @include extra-large{
            font-size: 2.4rem;
            line-height: 3.2rem;
          }
        }

        .btn {
          margin: 3.2rem 0 0;
          @include extra-large{
            margin: 4.6rem 0 0;
            font-size: 3.2rem;
            padding: 2rem 4rem;
          }
        }
      }
    }
  }
}
