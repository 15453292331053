.text-blocks {
  margin: 12rem 0;

  & > div > div.hero__right__button {
    margin: 4rem auto 0 auto;
    width: max-content;
  }

  @include tablet {
    margin: 8rem 0 4rem;
  }

  &__blocks {
    display: flex;
    flex-wrap: wrap;
    margin: 8rem -1.2rem -2.4rem;

    @include extra-large {
      margin: 12rem -1.2rem -2.4rem;
    }

    @include tablet {
      margin: 4rem -1.2rem -2.4rem;
    }
  }

  &__block {
    width: 33.3333%;
    padding: 0 1.2rem;
    height: auto;
    margin: 0 0 2.4rem;

    @include tablet {
      width: 100%;
    }

    &:nth-child(3n + 1) {
      .text-blocks__block__wrap {
        background-color: #f5f6fe;
      }
    }

    &:nth-child(3n + 2) {
      .text-blocks__block__wrap {
        background-color: #eaeaf7;
      }
    }

    &:nth-child(3n + 3) {
      .text-blocks__block__wrap {
        background-color: #e0e1f9;
      }
    }

    &__wrap {
      background-color: #f4f9ff;
      height: 100%;
      padding: 3.2rem 1.6rem 3.2rem 3.2rem;
      border-radius: 0.8rem;

      @include extra-large {
        padding: 3.8rem 2.4rem 3.8rem 3.8rem;
      }

      > h4 {
        @include extra-large {
          font-size: 4rem;
          line-height: 6rem;
        }
      }

      @include mobile {
        padding: 2.4rem;
      }

      p {
        margin: 2.4rem 0 0;
        padding: 0 1.6rem 0 0;

        @include extra-large {
          font-size: 2.4rem;
          line-height: 3.2rem;
          margin: 3rem 0 0;
          padding: 0 2.4rem 0 0;
        }

        @include mobile {
          margin: 1.6rem 0 0;
        }
      }
    }
  }

  &__center__button {
    display: flex;
    justify-content: center;
    margin-top: 8rem;

    @include tablet {
      margin-top: 4rem;
    }
  }
}
