.execution {
  display: flex;
  padding: 120px 0px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid #070a21;

  @include mobile;
  @include tablet {
    height: auto;
    padding: 80px 0 40px 0;
  }

  &__textbox {
    text-align: center;
    margin-bottom: 80px;

    @include mobile;
    @include tablet {
      margin-bottom: 40px;
    }

    @include extra-large {
      margin-bottom: 128px;
    }
    &__minHead {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-bottom: 24px;
      > p {
        font-size: 12px;
        font-weight: 600;
        @include extra-large {
          font-size: 20px;
        }
      }
    }


    > h2 {
      font-size: 48px;
      line-height: 64px;
      margin-bottom: 40px;

      @include extra-large {
        font-size: 68px;
        margin-bottom: 32px;
      }

      @include mobile;
      @include tablet {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 24px;
        font-weight: 500;
      }
    }

    > p {
      font-size: 20px;
      color: #4b4d66;
      line-height: 34px;

      @include extra-large {
        font-size: 28px;
        line-height: 48px;
      }

      @include mobile;
      @include tablet {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        width: 100%;
      }
    }
  }

  &__boxes {
    display: flex;
    gap: 24px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    @include mobile;
    @include tablet {
      flex-direction: column;
      gap: 24px;
      align-items: center;
    }

    &__box {
      width: 100%;
      background-color: #f5f6fe;
      border-radius: 16px;

      @include mobile;
      @include tablet {
        max-width: none;
      }

      &__inner {
        padding: 32px;
        text-align: left;

        @include extra-large {
          padding: 60px 48px 60px 60px;
        }

        @include mobile;
        @include tablet {
          padding: 24px;
          align-items: center;
          display: flex;
          flex-direction: column;
        }

        > img {
          width: 88px;
          height: 88px;
        }
        > h2 {
          margin: 48px 0px 24px 0px;
          font-size: 28px;
          line-height: 40px;

          @include extra-large {
            font-size: 42px;
          }

          @include mobile;
          @include tablet {
            margin-top: 24px;
            font-size: 22px;
            line-height: 32px;
            font-weight: 500;
          }
        }
        > p {
          font-size: 16px;
          line-height: 24px;
          color: #2b2f40;

          @include extra-large {
            margin:24px 0px ;
            font-size: 24px;
            line-height: 36px;
          }

          @include mobile;
          @include tablet {
            margin: 16px 0 0 0;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            text-align: center;
          }
        }
      }
    }
  }
}

#execution__container{
  width: 100%;
  background-color: white;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}